<template>
  <div>
    <el-form :model="form" ref="form" :hide-required-asterisk="true">
      <el-form-item
        :label="$t('First Name')"
        prop="firstname"
        :rules="[{ required: true, message: 'The field is required'}]"
      >
        <el-input type="text" v-model="form.firstname"></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('Last Name')"
        prop="lastname"
        :rules="[{ required: true, message: 'The field is required'}]"
      >
        <el-input type="text" v-model="form.lastname"></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('user.email')"
        prop="email"
        :rules="[{ required: true, message: 'The field is required'},{ type: 'email', message: 'The format email invalid'}]"
      >
        <el-input type="text" v-model="form.email" placeholder="example@mail.com"></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('user.phone')"
        prop="phone"
        :rules="[
          { required: true, message: 'The field is required'},
          { pattern:/^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/, message: 'Format phone invalid'}
        ]"
      >
        <el-input type="text" v-model="form.phone" placeholder="e.g (414) 639-1121" v-mask="'(###) ###-####'"></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('user.office')"
        prop="officeUser.office"
        :rules="[{ required: true, message: 'The field is required'}]"
      >
        <el-select v-model="form.officeUser.office" placeholder="Select office" class="w-100">
          <el-option v-for="item in offices" :key="item.id" :label="item.office_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('Status')" prop="status" >
        <el-select  v-model="form.status" placeholder="Select Status"  class="w-100" >
          <el-option value="1" label="Active">Active</el-option>
          <el-option value="0" label="Inactive">Inactive</el-option>
        </el-select>
      </el-form-item>
      <el-button type="primary" @click="(!data)?submitForm('form'):updateForm('form')">Save User</el-button>
    </el-form>
  </div>
</template>

<script>
import office from "@/services/api/office";
import user from "@/services/api/user";
import officeUser from "@/services/api/officeUser";
import service from "@/services/api/service";

export default {
  props: ["data"],
  data() {
    return {
      offices: [],      
      services: [],
      value: [],
      form: {
        id: null,
        firstname: null,
        lastname: null,
        email: null,
        old_email: null,
        phone: null,
        discriminator: 'employee',
        status: null,
        officeUser: {
          id: null,
          office: null,
          user: null,
          owner: this.$store.getters.id
        }
      }
    };
  },
  mounted() {
    
    service.get().then(response => {
      this.services = response;
    });
    //
    switch (this.$store.getters.discriminator) {
      case "administrator":
        this.discriminator = "administrator";
        office.get().then(response => {
          this.offices = response;
        });
        break;
      case "office":
      case "employee":
        this.discriminator = "office";
        officeUser.getOffice(this.$store.getters.id).then(response => {
          this.offices = response.map(item => {
            return {
              id: item.office.id,
              office_name: item.office.office_name
            };
          });
        });
        break;
    }
    //
    if (this.data) {
      this.form.id = this.data.user.id;
      this.form.firstname = this.data.user.firstname;
      this.form.lastname = this.data.user.lastname;
      this.form.email = this.data.user.email;
      this.form.old_email = this.data.user.email;
      this.form.phone = this.data.user.phone;
      this.form.discriminator = this.data.user.discriminator;
      //
      this.form.officeUser.id = this.data.id;
      this.form.officeUser.office = this.data.office.id;
      this.form.officeUser.user = this.data.user.id;
      this.form.status = this.data.user.isActive.toString();
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          user
            .create(this.form)
            .then(response => {
              this.form.officeUser.user = response.id;
              //
              officeUser
                .create(this.form.officeUser)
                .then(result => {
                  this.$emit("refresh", {
                    data: result,
                    update: false
                  });
                })
                .catch(() => {
                  this.$message({
                    message: this.$store.getters.message,
                    type: "error",
                    customClass: "message-error"
                  });
                });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.error,
                type: "error",
                customClass: "message-error"
              });
            });
        } else {
          return false;
        }
      });
    },
    updateForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          user
            .update(this.form)
            .then(response => {
              this.form.officeUser.user = response.id;
              //
              officeUser
                .update(this.form.officeUser)
                .then(result => {
                  this.$emit("refresh", {
                    data: result,
                    update: true
                  });
                })
                .catch(() => {
                  this.$message({
                    message: this.$store.getters.error,
                    type: "error",
                    customClass: "message-error"
                  });
                });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.error,
                type: "error",
                customClass: "message-error"
              });
            });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss">
</style>
