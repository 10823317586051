<template>
  <div class="bg-white shadow-sm rounded-lg p-4">
    <div class="row">
      <div v-if="this.$store.getters.discriminator==='administrator'" class="col-3 ml-0 p-0">
        <multiselect v-model="oficeFilter" @input="filterData" :options="offices" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Offices" label="office_name" track-by="id" :select-label="''" :deselect-label="''" @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.map(item=>{return item.office_name}).join(", ") }}</span></template>
        </multiselect>
      </div>
      <div class="col-3 ml-2 p-0">
        <multiselect v-model="activeFilter" @input="filterData" :options="[{label:'Active',value:1},{label:'Inactive',value:0}]" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Show" label="label" track-by="value" :select-label="''" :deselect-label="''" @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.map(item=>{return item.label}).join(", ") }}</span></template>
        </multiselect>
      </div>
    </div>
    <el-table 
      :data="this.tableData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()) || data.email.toLowerCase().includes(search.toLowerCase()) || data.phone.toLowerCase().includes(search.toLowerCase()) || data.office.toLowerCase().includes(search.toLowerCase()))" 
      :default-sort="{ prop: 'user.firstname', order: 'ascending' }"
      class="w-100">
      <el-table-column sortable prop="user.firstname" label="First Name"></el-table-column>
      <el-table-column sortable prop="user.lastname" label="Last Name"></el-table-column>
      <el-table-column sortable prop="user.email" label="Email" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column sortable prop="user.invitation" label="Invitation" width="120">
        <template slot-scope="scope">
          <i v-if="scope.row.user.invitation" class="fal fa-check-circle text-success"></i>
          <i v-else class="fal fa-times-circle text-danger"></i>
        </template>
      </el-table-column>
      <el-table-column prop="user.isActive" label="State" width="120">
        <template slot-scope="scope">
          <i v-if="scope.row.user.isActive" class="fal fa-check-circle text-success"></i>
          <i v-else class="fal fa-ban text-danger"></i>
        </template>
      </el-table-column>
      <el-table-column sortable prop="user.phone" label="Phone"></el-table-column>
      <el-table-column sortable  prop="office.office_name" label="Office"></el-table-column>
      <el-table-column label="Operations">
        <template slot="header" slot-scope="scope">
          <el-input v-model="search" placeholder="Search..." class="p-0" :key="scope.row" />
        </template>
        <template slot-scope="scope">
          <el-button-group>
            <el-button size="mini" icon="el-icon-edit" @click="update(scope.row,scope.$index)"></el-button>
            <el-button size="mini" icon="el-icon-s-promotion"></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-button class="fixed-bottom new-register" type="success" @click="add">Add New User</el-button>

    <el-drawer title="User" :visible.sync="component.drawer" direction="rtl" size="500px">
      <div class="pt-3 pl-5 pr-5 pb-5">
        <component-user v-on:refresh="load($event)" :data="component.data" :key="component.render" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import office from "@/services/api/office";
import officeUser from "@/services/api/officeUser";
import ComponentUser from "@/components/admin/User";
import Multiselect from 'vue-multiselect';

export default {
  components: {
    ComponentUser,
    Multiselect
  },
  data() {
    return {
      data:[],
      tableData: [],
      oficeFilter:[],
      activeFilter:[{label:'Active',value:1}],
      search:"",
      offices: [], 
      component: {
        data: null,
        drawer: false,
        render: 0
      },
      index: null
    };
  },
  mounted() {
    office.get().then(response => {
          this.offices = response;
        });
    switch (this.$store.getters.discriminator) {
      case "administrator":
        this.discriminator = "administrator";
        officeUser.getUser(this.$store.getters.id).then(response => {
          this.data = Object.assign([], response);
          this.tableData = response;
          this.tableData=this.tableData.filter(item=>item.user.isActive==1);
          this.tableData.sort(this.compare);
        });
        break;
      case "office":
      case "employee":
        officeUser.getOffice(this.$store.getters.id).then(response => {
            let idOffice=response[0].office.id;
            officeUser.getUsersOffice(idOffice).then(response => {
              this.data = Object.assign([], response);
              this.tableData = response;
              this.tableData=this.tableData.filter(item=>item.user.isActive==1);
              this.tableData.sort(this.compare);
            });
        }); 
        break;
    }
  },
  methods: {
    compare(a, b) {
        if (a.user.isActive > b.user.isActive) return -1;
        if (b.user.isActive > a.user.isActive) return 1;
        return 0;
    },
    load(event) {
      this.component.drawer = false;
      let idOffices = this.oficeFilter.map(office=>{
          return office.id;
        });
      if (event.update) {
        if(idOffices.length>0 && idOffices.indexOf(event.data.office.id)===-1){
          this.tableData.splice(this.index, 1);
        }else{
          this.tableData.splice(this.index, 1, event.data);
        }
        let indexUpdate=null;
        this.data.forEach(function(element, index, array){
          if(element.id==event.data.id){
            indexUpdate=index;
          }
        });

        this.data.splice(indexUpdate, 1, event.data);
      } else {
        if(idOffices.length>0){
          if(idOffices.indexOf(event.data.office.id)!=-1){
            this.tableData.unshift(event.data);
          }
        }else{
          this.tableData.unshift(event.data);
        }
        this.data.unshift(event.data);
      }
      this.tableData.sort(this.compare);
      this.filterData();
    },
    filterData(){
      let idOffices = this.oficeFilter.map(office=>{
        return office.id;
      });
      let active = this.activeFilter.map(active=>{
        return active.value;
      });
      if(active.length===0){
        this.activeFilter.push({label:'Active',value:1});
        active.push(1);
      }
      if(idOffices.length>0){
        this.tableData=this.data.filter(item=>idOffices.indexOf(item.office.id)!=-1);
      }else{
        this.tableData = Object.assign([], this.data);
      }
      this.tableData=this.tableData.filter(item=>active.indexOf(item.user.isActive)!=-1);
      this.tableData.sort(this.compare);
    },
    add() {
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = null;
    },
    update(row, index) {
      this.index = index;
      //
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = row;
    }
  }
};
</script>
